export const ERROR_NAMES = {
  TOKEN_EXPIRED_ERROR: 'TokenExpiredError',
  JSON_WEB_TOKEN_ERROR: 'JsonWebTokenError',
};

export const LOG_EVENTS = {
  GENERIC_ERROR: 'GenericError',
  EXPIRED_TOKEN_EXCEPTION: 'ExpiredTokenException',
  DECODING_JWT_EXCEPTION: 'DecodingJWTException',
  SUCCESS_RESET_PASSWORD: 'SuccessResetPassword',
  CHANGE_PASSWORD_EXCEPTION: 'ChangePasswordException',
};

export const LOG_MESSAGES = {
  SUCCESS: 'Password Changed with Success',
};

export const PASSWORD_STRENGTH = [
  {
    TEXT: 'Senha não aceita',
    COLOR: '#EA001C',
  },
  {
    TEXT: 'Senha não aceita',
    COLOR: '#EA001C',
  },
  {
    TEXT: 'Fraca',
    COLOR: '#FFAF66',
  },
  {
    TEXT: 'Regular',
    COLOR: '#FFAF66',
  },
  {
    TEXT: 'Boa',
    COLOR: '#44BE30',
  },
  {
    TEXT: 'Ótima',
    COLOR: '#44BE30',
  },
];
