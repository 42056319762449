import { createContext, useState } from 'react';

export type ButtonType = null | 'CHAT' | 'LINK';

export interface FeedbackType {
  error: {
    button_type: ButtonType;
    title: string;
    tracker_id: string | null;
    type: string;
  };
  message: string;
  success: boolean;
  user?: {
    email: string;
    clientId: string;
    trackerId: string;
  };
}

export interface IFeedbackContext extends FeedbackType {
  setFeedback: (data: FeedbackType) => void;
}

export const DEFAULT_FEEDBACK: FeedbackType = {
  error: {
    button_type: null,
    title: 'Ops! Parece que algo deu errado!',
    tracker_id: null,
    type: 'DefaultException',
  },
  message: 'Entre em contato com a gente para resolvermos essa questão.',
  success: false,
  user: {
    email: '',
    clientId: '',
    trackerId: '',
  },
};

export const feedbackContext = createContext<IFeedbackContext>({
  ...DEFAULT_FEEDBACK,
  setFeedback: () => {
    null;
  },
});

export const useFeedback = () => {
  const [feedback, setFeedback] = useState<FeedbackType>(DEFAULT_FEEDBACK);

  return {
    ...feedback,
    setFeedback,
  };
};
