import { Box } from '@material-ui/core';
import React from 'react';
import styles from './CustomContainer.module.scss';

const CustomContainer: React.FC = ({ children }) => {
  return (
    <Box className={styles.BoxCustomContainer}>
      <div className={styles.TitleContainer}>
        {children}
      </div>
    </Box>
  ) 
};

export default CustomContainer;
