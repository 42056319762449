export const ROUTES = {
  HOME: '/',
  ERROR_TYPE: '/erro/',
  SUCCESS: '/sucesso',
  RESEND_SUCCESS: '/sucesso-reenvio',
  ERROR: '/erro',
};

export const ERROR_ROUTES = {
  RESEND_EMAIL: 'erro-ao-reenviar-link',
};
