export const FEEDBACKS = {
  CHAT: {
    buttonTitle: 'Falar pelo chat',
  },
  LINK: {
    title: 'Ops! Sua solicitação expirou',
    message:
      '<span>Precisamos que você solicite um novo e-mail de redefinição de senha.</span><br /> <span><strong>Fique atento:</strong> essa solicitação expira em 24h.</span>',
    buttonTitle: 'Reenviar e-mail',
  },
  DEFAULT: {
    title: 'Ops! Parece que algo deu errado!',
    message:
      '<span>A resposta do nosso servidor está um pouco lenta.</span><br /><span>Aguarde alguns minutos e tente novamente.</span>',
  },
};
