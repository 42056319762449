import { verify } from 'jsonwebtoken';
import { URLSearchParams } from 'url';

import { ERROR_NAMES, LOG_EVENTS } from '../constants/error.constants';
import { FEEDBACKS } from '../constants/feedback.constants';
import { FeedbackType } from '../contexts/feedback.context';

import { sendLogs } from './send-logs.service';

interface ILogError {
  event: string;
  message: string;
}

interface IResponseIsSignedIn {
  path: string;
  feedback: FeedbackType;
}

export const isSignedIn = (query: URLSearchParams): IResponseIsSignedIn | false => {
  if (!query) return false;

  /**
    t=token
    c=client_id
    l=lang
    tr=tracker_id
  **/
  const t = query.get('t');
  const c = query.get('c');
  const l = query.get('l');
  const tr = query.get('tr');

  const privateKey = process.env.REACT_APP_JWT_PRIVATE_KEY;

  try {
    if (!t) {
      throw new Error('Error ao encontrar o token para fazer decode');
    }

    const decoded = verify(t, privateKey);

    localStorage.setItem(
      'data',
      JSON.stringify({
        client_id: c,
        tracker_id: tr,
        lang: l || 'pt_BR',
        token: t,
        user_ref: decoded.e,
      }),
    );
  } catch (err) {
    const lang = l || 'pt_BR';
    localStorage.setItem('data', JSON.stringify({ lang: lang }));

    let log_info: ILogError;
    let feedback: FeedbackType;
    let dataReturn: IResponseIsSignedIn;

    if (err.name === ERROR_NAMES.TOKEN_EXPIRED_ERROR) {
      //realiza o envio de logs da decodificação ao backend
      log_info = { event: LOG_EVENTS.EXPIRED_TOKEN_EXCEPTION, message: err.message };
      sendLogs(log_info);

      const decoded = verify(t, privateKey, { ignoreExpiration: true });

      localStorage.setItem(
        'data',
        JSON.stringify({
          client_id: c,
          tracker_id: tr,
          lang: l || 'pt_BR',
          user_ref: decoded.e,
        }),
      );

      feedback = {
        error: {
          button_type: 'LINK',
          title: FEEDBACKS.LINK.title,
          tracker_id: null,
          type: ERROR_NAMES.TOKEN_EXPIRED_ERROR,
        },
        message: FEEDBACKS.LINK.message,
        success: false,
        user: {
          email: decoded.e,
          clientId: c,
          trackerId: tr,
        },
      };

      dataReturn = {
        path: '/erro/TokenExpiredError',
        feedback,
      };

      return dataReturn;
    } else if (err.name === ERROR_NAMES.JSON_WEB_TOKEN_ERROR) {
      log_info = { event: LOG_EVENTS.DECODING_JWT_EXCEPTION, message: err.message };
      sendLogs(log_info);

      feedback = {
        error: {
          button_type: null,
          title: FEEDBACKS.DEFAULT.title,
          tracker_id: null,
          type: ERROR_NAMES.JSON_WEB_TOKEN_ERROR,
        },
        message: FEEDBACKS.DEFAULT.message,
        success: false,
      };
      dataReturn = {
        path: '/erro/JsonWebTokenError',
        feedback,
      };
      return dataReturn;
    }

    throw new Error('Error Decode');
  }
};
