import { Box } from '@material-ui/core';
import { PASSWORD_STRENGTH } from '../../constants/error.constants';
import styles from './passwordStrength.module.scss';

interface IPasswordStrength {
  strength: number;
}

const PasswordStrength: React.FC<IPasswordStrength> = ({ strength }) => {
  return (
    <div className={styles.MainDiv}>
      <div className={styles.StrengthDiv}>
        <Box
          className={styles.StrengthMeter}
          style={{ width: 'calc(20% * (' + (strength+1) + '))', background: PASSWORD_STRENGTH[strength]?.COLOR }}
        ></Box>
      </div>

      <p className={styles.StrengthText}>{PASSWORD_STRENGTH[strength+1]?.TEXT}</p>
    </div>
  );
};

export default PasswordStrength;
