import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomContainer from '../../components/CustomContainer';
import { API } from '../../constants/api.constants';
import { ERROR_NAMES } from '../../constants/error.constants';
import { ERROR_ROUTES, ROUTES } from '../../constants/routes.constants';
import { DEFAULT_FEEDBACK, feedbackContext } from '../../contexts/feedback.context';
import { IResendEmailResponse } from '../../interfaces/api';
import { IDataStorage } from '../../interfaces/storage';
import { postTemflow } from '../../services/axios.service';
import styles from '../../styles/Error.module.scss';
import { translate } from '../../utils/translate.utils';

const Error = () => {
  const { error, setFeedback } = useContext(feedbackContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const dataStorage: IDataStorage = JSON.parse(localStorage.getItem('data'));

  const sendToError = () => {
    setFeedback(DEFAULT_FEEDBACK);
    history.push(ROUTES.ERROR_TYPE + ERROR_ROUTES.RESEND_EMAIL);
  };
  const handleSubmit = async () => {
    setLoading(true);
    if (dataStorage.user_ref) {
      try {
        const result = await postTemflow<IResendEmailResponse>(API.RECOVERY_PASSWORD, { email: dataStorage.user_ref });

        result?.email_send_queue ? history.push(ROUTES.RESEND_SUCCESS) : sendToError();
      } catch {
        sendToError();
      } finally {
        setLoading(false);
      }
    }
  };

  const values = {
    TokenExpiredError: {
      title: translate('ERROR.TOKEN_EXPIRED.TITLE'),
      subtitle: [translate('ERROR.TOKEN_EXPIRED.SUBTITLE_1')],
    },
    JsonWebTokenError: {
      title: translate('ERROR.JSON_WEB_TOKEN.TITLE'),
      subtitle: [translate('ERROR.JSON_WEB_TOKEN.SUBTITLE_1'), translate('ERROR.JSON_WEB_TOKEN.SUBTITLE_2')],
    },
    DefaultException: {
      title: translate('ERROR.GENERIC.TITLE'),
      subtitle: [translate('ERROR.GENERIC.SUBTITLE_1')],
    },
  };

  return (
    error.type && (
      <CustomContainer>
        <img className={styles.Image} src="/images/empty_bike.svg" alt="bike vazia" />

        <h1 className={styles.Title}>{values[error.type].title}</h1>

        {values[error.type].subtitle.map((p, index) => {
          return <p key={index}>{p}</p>;
        })}

        {error.type === ERROR_NAMES.TOKEN_EXPIRED_ERROR && (
          <>
            <p>
              <strong> {translate('ERROR.TOKEN_EXPIRED.ATTENTION_STRONG')}</strong>{' '}
              {translate('ERROR.TOKEN_EXPIRED.ATTENTION_INFO')}
            </p>
            <CustomButton placeholder="Reenviar email" loading={loading} disabled={loading} submit={handleSubmit} />
          </>
        )}
      </CustomContainer>
    )
  );
};

export default Error;
