import CustomContainer from '../../components/CustomContainer';

import styles from '../../styles/Success.module.scss';

import { translate } from '../../utils/translate.utils';

const ResendSuccess = () => {
  return (
    <CustomContainer>
      <img className={styles.Image} src="/images/email-enviado.svg" />
      <h1 className={styles.Title}>{translate('SUCCESS_LINK.TITLE')}</h1>
      <p className={styles.Subtitle}>{translate('SUCCESS_LINK.MESSAGE')}</p>
    </CustomContainer>
  );
};

export default ResendSuccess;
