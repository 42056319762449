import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';

import { defaultLanguage, languages } from './config';

const locales = languages.reduce((locales, { lang }) => {
  locales[lang] = { translations: require(`../locales/${lang}/translation.json`) };
  return locales;
}, {});

const detection: DetectorOptions = {
  // order and from where user language should be detected
  order: ['querystring'],

  // keys or params to lookup language from
  lookupCookie: 'lng',
  lookupLocalStorage: 'lng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection,
    fallbackLng: defaultLanguage,
    resources: locales,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
    },
    nonExplicitSupportedLngs: true,
  });

export default i18next;
