import { Box } from '@material-ui/core';
import styles from './App.module.scss';
import { feedbackContext, useFeedback } from './contexts/feedback.context';
import Routes from './routes';
import CssBaseline from '@material-ui/core/CssBaseline';

const App: React.FC = () => {
  const feedbackValues = useFeedback();

  return (
    <Box className={styles.App}>
      <CssBaseline />
      <Box display="flex" justifyContent="center">
        <feedbackContext.Provider value={feedbackValues}>
          <Routes />
        </feedbackContext.Provider>
      </Box>
    </Box>
  );
};

export default App;
