import CustomContainer from '../../components/CustomContainer';

import styles from '../../styles/Success.module.scss';

import { translate } from '../../utils/translate.utils';

const Success = () => {
  return (
    <CustomContainer>
      <img className={styles.Image} src="/images/ilustra-bikeItau-02.png" />
      <h1 className={styles.Title}>{translate('SUCCESS.TITLE')}</h1>
      <p className={styles.Subtitle}>{translate('SUCCESS.FIRST_SUBTITLE')}</p>
      <p className={styles.Subtitle}>{translate('SUCCESS.SECOND_SUBTITLE')}</p>
    </CustomContainer>
  );

}

export default Success;
