import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { ROUTES } from './constants/routes.constants';
import Error from './pages/error';
import Home from './pages/home';
import ResendSuccess from './pages/resend-success';
import Success from './pages/success';

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={ROUTES.HOME} exact>
          <Home />
        </Route>
        <Route path={ROUTES.ERROR}>
          <Error />
        </Route>
        <Route path={ROUTES.RESEND_SUCCESS} exact>
          <ResendSuccess />
        </Route>
        <Route path={ROUTES.SUCCESS} exact>
          <Success />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
