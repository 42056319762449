import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styles from './CustomButton.module.scss';

interface ICustomComponent{
  submit: () => void;
  loading: boolean;
  disabled: boolean;
  placeholder: string;
}

const CustomButton: React.FC<ICustomComponent> = ({ submit, loading, disabled = false, placeholder }) => {
  return (
    <button disabled={disabled} onClick={submit} className={styles.Button} type="submit">
      <span role="status" aria-hidden="true">
        {loading ? <CircularProgress color="primary" size={18}/> : placeholder}
      </span>
    </button>
  );
};

export default CustomButton;
