import { useEffect, useState, useContext } from 'react';

import zxcvbn from 'zxcvbn';
import i18next from 'i18next';
import CustomContainer from '../../components/CustomContainer';
import PasswordStrength from '../../components/PasswordStrength';
import CustomInput from '../../components/CustomInput';
import CustomButton from '../../components/CustomButton';

import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from '../../styles/Home.module.scss';

import { ROUTES } from '../../constants/routes.constants';
import { FEEDBACKS } from '../../constants/feedback.constants';

import { feedbackContext, FeedbackType } from '../../contexts/feedback.context';

import { isSignedIn } from '../../services/decode-jwt.service';
import { useQuery } from '../../utils/routes.utils';
import { useHistory } from 'react-router-dom';
import { postTemflow, putTemflow } from '../../services/axios.service';
import { API } from '../../constants/api.constants';
import { IRecoveryPasswordResponse, ITokenErrorResponse, ITokenResponse } from '../../interfaces/api';
import { IDataStorage } from '../../interfaces/storage';
import { ERROR_NAMES } from '../../constants/error.constants';
import { ISendLogs, sendLogs } from '../../services/send-logs.service';
import { LOG_EVENTS, LOG_MESSAGES } from '../../constants/error.constants';

const Home = () => {
  const query = useQuery();
  const history = useHistory();
  const { setFeedback } = useContext(feedbackContext);

  const [pageLoaded, setPageLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [strength, setStrength] = useState(-1);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [messageError, setMessageError] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const dataStorage: IDataStorage = JSON.parse(localStorage.getItem('data'));

  const handleSubmit = async () => {
    setLoading(true);

    const data = {
      new_password: password,
      new_password_confirmation: confirmPassword,
      token: dataStorage.token,
    };

    try {
      const recoveryPassword = await putTemflow<IRecoveryPasswordResponse>(API.RESET_PASSWORD, data);

      if (recoveryPassword.has_new_password_changed) {
        const successLogs: ISendLogs = {
          event: LOG_EVENTS.SUCCESS_RESET_PASSWORD,
          message: LOG_MESSAGES.SUCCESS,
          tracker_id: dataStorage.tracker_id,
          user_ref: dataStorage.user_ref,
        };
        sendLogs(successLogs);
        history.push(ROUTES.SUCCESS);
      }
    } catch (error) {
      let errorLogs: ISendLogs;
      if (error.response) {
        errorLogs = {
          event: LOG_EVENTS.CHANGE_PASSWORD_EXCEPTION,
          message: error.response.data?.message,
          user_ref: dataStorage.user_ref,
          tracker_id: dataStorage.tracker_id,
        };
      } else {
        errorLogs = {
          event: LOG_EVENTS.GENERIC_ERROR,
          message: error.message,
          user_ref: dataStorage.user_ref,
          tracker_id: dataStorage.tracker_id,
        };
      }
      sendLogs(errorLogs);
      history.push(ROUTES.ERROR);
    } finally {
      setLoading(false);
    }
  };

  const setLanguage = () => {
    const language: IDataStorage = JSON.parse(localStorage.getItem('data'));
    i18next.changeLanguage(language.lang);
  };

  useEffect(() => {
    validateTokenJWT();
  }, []);

  const validateTokenJWT = async () => {
    try {
      const nextRoute = isSignedIn(query);

      if (nextRoute) {
        setFeedback(nextRoute.feedback);
        history.push(nextRoute.path);
      } else {
        const tokenValidationResponse = await postTemflow<ITokenResponse & ITokenErrorResponse>(API.VALIDATE_TOKEN, {
          token: dataStorage.token,
        });

        if (tokenValidationResponse.status === 400) {
          const feedback: FeedbackType = {
            error: {
              button_type: 'LINK',
              title: FEEDBACKS.LINK.title,
              tracker_id: tokenValidationResponse.data.error.tracker_id,
              type: ERROR_NAMES.TOKEN_EXPIRED_ERROR,
            },
            message: FEEDBACKS.LINK.message,
            success: false,
          };

          setFeedback(feedback);

          history.push(ROUTES.ERROR_TYPE + feedback.error.type);
        }
      }
    } catch (e) {
      history.push(ROUTES.ERROR);
    } finally {
      setLanguage();
      setPageLoaded(true);
    }
  };

  useEffect(() => {
    setMessageError(!confirmPassword || confirmPassword === password ? null : 'As duas senhas precisam ser iguais.');

    password ? setStrength(zxcvbn(password).score) : setStrength(-1);

    setFormValid(
      !!password && !!confirmPassword && confirmPassword === password && password.length > 7 && strength > 0,
    );
  }, [confirmPassword, password]);

  return pageLoaded ? (
    <CustomContainer>
      <Box>
        <h1>Crie uma nova senha</h1>
        <p>Digite sua nova senha abaixo.</p>
        <p>Depois, repita a mesma senha para confirmar.</p>

        <CustomInput
          setValue={setPassword}
          setVisibility={setVisibility}
          visibility={visibility}
          placeholder="Senha*"
        />

        <PasswordStrength strength={strength} />

        <CustomInput
          setValue={setConfirmPassword}
          visibility={visibility}
          setVisibility={setVisibility}
          errorMessage={messageError}
          placeholder="Confirmação de Senha*"
        />

        <Box className={styles.Badges}>
          <span>
            <span>Torne sua senha mais segura:</span>
            <ul>
              <li>Use mais de 8 dígitos;</li>
              <li>Combine letras maiúsculas e minúsculas e números;</li>
              <li>Defina uma senha diferente da anterior;</li>
              <li>Não use informações pessoais;</li>
            </ul>
          </span>
        </Box>

        <CustomButton placeholder="Continuar" submit={handleSubmit} loading={loading} disabled={!formValid} />
      </Box>
    </CustomContainer>
  ) : (
    <Box className={styles.SpinnerContainer}>
      <CircularProgress color="secondary" />
    </Box>
  );
};

export default Home;
