import { AxiosResponse } from 'axios';
import { api } from './axios.service';

interface IData {
  event: string;
  message: string;
  user_ref?: string;
}

export interface ISendLogs extends IData {
  tracker_id?: string;
}

export const sendLogs = (logs: ISendLogs): Promise<AxiosResponse> => {
  const router = process.env.REACT_APP_LOGS_ROUTE;
  const serviceAuthorization = process.env.REACT_APP_SERVICE_AUTHORIZATION;
  const data: IData = {
    event: logs.event,
    message: logs.message,
    user_ref: logs.user_ref || null,
  };
  const headers = {
    tracker_id: logs.tracker_id || null,
    'service-authorization': serviceAuthorization,
    'Content-Type': 'application/json',
  };

  return api.post(router, data, { headers });
};
