import axios from 'axios';
import { IResponse } from '../interfaces/api';

export const api = axios.create();

const defaultHeaders = {
  'Content-Type': 'application/json',
  'Accept-Language': 'PT-br',
};

const baseUrl = process.env.REACT_APP_BASE_URL;

export async function postTemflow<T extends IResponse>(url: string, data: any, customHeaders?: any): Promise<T> {
  return await temFlow(url, 'post', data, customHeaders);
}
export async function putTemflow<T extends IResponse>(url: string, data: any, customHeaders?: any): Promise<T> {
  return await temFlow(url, 'put', data, customHeaders);
}

async function temFlow<T>(url: string, method: string, data?: any, customHeaders?: any): Promise<T> {
  const storage = JSON.parse(localStorage.getItem('data'));

  defaultHeaders['client-id'] = storage.client_id;
  defaultHeaders['tracker_id'] = storage.tracker_id || null;
  const headers = { ...defaultHeaders, ...customHeaders };

  try {
    const response = await axios[method]<T>(`${baseUrl}${url}`, data, { headers });

    return response.data;
  } catch (error) {
    if (error.response.status === 400 && method === 'post') {
      return error.response;
    }

    throw new Error(error.response);
  }
}
