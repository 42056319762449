import { Box } from '@material-ui/core';
import React from 'react';
import styles from './CustomInput.module.scss';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

interface ICustomComponent {
  visibility: boolean; 
  setVisibility: (value: boolean) => void;
  setValue: (value: string) => void;
  placeholder: string; 
  errorMessage?: string;
}

const CustomInput: React.FC<ICustomComponent> = ({ visibility, setVisibility, setValue, placeholder, errorMessage = '' }) => {
  return (
    <Box>
      <Box className={styles.InputFilds}>
        <input
          type={visibility ? 'text' : 'password'}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
        />

        <span onClick={() => setVisibility(!visibility)}>
          {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </span>
      </Box>
      {!!errorMessage && <p className={styles.ErrorMessage}>{errorMessage}</p>}
    </Box>
  );
};

export default CustomInput;
